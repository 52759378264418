import React from "react"

import Layout from '../../components/layout';
import {Helmet} from "react-helmet";
import { Link } from 'gatsby';
import { Title, CompanyPlaceInfo } from '../../components/Globals';


const CareerPage = () => {
  const ref = React.createRef();

  const handleClick = () =>
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  const [ExploreJobs] = React.useState([

    {
      id: 1,
      title: 'Students/interns',
      desc: 'Work and learn with the best offshore software development company through internships and major projects.',
    },
    {
      id: 2,
      title: 'Entry-level professionals',
      desc: 'If you are career-driven and love to engage in challenging solutions, then join a reliable salesforce consulting partner to become the professional of your dreams. We take the responsibility for a career that rises sky high.'
    },
    {
      id: 3,
      title: 'Experienced professionals',
      desc: 'Salesforce-based company in USA - Cymetrix Software aims at presenting out-of-the-box solutions. Our strategies combined with your intellect will result in fruitful outcomes desired by all parties involved. '
    },
  ]);

  const [AreaInterset] = React.useState([
    {
      id: 1,
      title: 'Salesforce Consulting',
      url:'/services/salesforce-consulting',
      desc: 'We are a salesforce certified consulting partner and expert salesforce implementation partners in USA and India. We cater to the needs of clients in India and abroad. We provide a 360° all-around salesforce consulting services support right from implementation to training.',
    },
    {
      id: 2,
      title: 'Digital Marketing',
      desc: 'We work as digital marketing partners for our clients worldwide. We help them establish themselves in the digital world and ace in their marketing strategies.'
    },
    {
      id: 3,
      title: 'Spend Analysis Consultant',
      url: '/services/spend-analysis',
      desc: 'Cymetrix Software also provides spend analysis consulting solutions. We aim at reducing procurement costs and enhancing efficiency at the same time.'
    },
  ]);
  const handleSubmit = (event) => {
    event.preventDefault();
    let headers = new Headers();
    headers.append('Authorization', 'Basic ' + btoa('api' + ":" + '5d2220e3b61e542f83de699a4ece81ad-4d640632-9b883e3e'));
    let formData = new FormData();
    formData.append('from', event.target.email.value);
    formData.append('to','sandip@cymetrixsoft.com');
    formData.append('subject', event.target.subject.value);
    formData.append('text', event.target.message.value);

    fetch('https://api.mailgun.net/v3/sandbox0f7d801b76fb45bba72ceff5856c284d.mailgun.org/messages', {
      method: 'post',
      headers: headers,
      body: formData,
    })
    .then(function (data) {
      if(data.status === 200) {
        //alert('Message sent successfuly');
        window.location = 'https://www.cymetrixsoft.com/Thankyou/';
      }
    })
    .catch(function (error) {
      console.log('Request failed', error);
    });
  }
  
  return(
    <Layout>
      <Helmet>
        <title>Tech Staff Augmentation | Salesforce, AI, Analytics | Cymetrix </title>
        <meta name="description" content="Hire top 1% niche technical on-demand talent in Salesforce, AI and Data Science & Analytics. Contact us for staff augmentation services. "/>
        <meta name="keywords" content="cymetrix,salesforce crm software implementation partners india,salesforce cpq partner india,salesforce.com crm partners india,salesforce partners in india,salesforce consultants india,salesforce marketing cloud partners india,salesforce partners in mumbai,salesforce partners in USA,salesforce implementation partners usa,SALESFORCE PARTNERS INDIA,salesforce consultants india, Cymetrix, cymetrixsoft, Cymetrixsoftware"/>
        <link rel="canonical" href="https://www.cymetrixsoft.com/services/staff-augmentation-services/" />
      </Helmet>
      <div className="staff-aug-background flex flex-col">
        <p className="block text-base xl:text-5xl mt-16 xl:px-48 xl:mt-20 md:mt-16 sm:mt-24 lg:text-4xl md:text-3xl sm:text-3xl sma:text-xl
        text-white text-uppercase text-center">
          Integrate niche IT experts within your team without any hassle
        </p>
        <p className="block text-base xl:text-xl mt-4 md:mt-6 lg:text-lg md:text-base sm:text-base sma:text-base
        text-white text-uppercase text-center">
          Scale up your team quickly and flexibly. Your success, our experts! 
        </p>
        <button class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 my-5 border border-blue-500 
        hover:border-transparent rounded mt-4 md:mt-10 "onClick={handleClick} >
          Get In Touch
        </button>
      </div>

      <div className="max-w-6xl mx-auto justify-center my-3 md:my-12 ">
          <h1 className="text-3xl my-3 sma:mx-3">Staff Augmentation Services By Cymetrix</h1>
          <p className="text-lg sma:mx-3">Feeling the project dragged due to lack of skilled workforce? Stop the talent hunt now! With our staff augmentation services, you can fill the skill gap with the top 1% of niched and skilled experts that you need for your project. </p>
      </div>

      <div className="product-background w-full flex flex-col items-center" title="">
                <div className="max-w-6xl mx-4 my-16">
                    <h1 className="text-white text-3xl text-left sma-xl font-thin">Key Benefits</h1>
                    <div class="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-14">
                    <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">NICHE TECH EXPERTISE</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">At Cymetrix, we provide niche IT experts, specializing in Salesforce and Data Analytics. Our team dives deep into these fields, so you don’t have to. They bring their expertise and experience straight to your project, ensuring it reaches its full potential. </div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">TOP 1% TALENT</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Why settle for anything less than the best? Our IT staff augmentation services connect you with the top 1% of talent, with several years of experience and expertise in over 100+ successful projects across multiple industries.</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">ON-DEMAND SCALABILITY</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">We understand the intricacies of every industry, whether you are looking to quickly scale up your team with niched experts or plan to scale down in the near future, we can offer an on-demand scalable team as per your needs and requirements. </div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">NO RECRUITMENT DELAYS</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Forget about the endless recruitment and interview process! With our pre-vetted talent pool, you can ditch the recruitment delays and quickly onboard skilled individuals for your ongoing or next project! </div>
                            </div>
                        </div>


                    </div>
                    <div className="flex flex-row justify-center">
                        <div className="bg-transparent hover:border-blue-500 text-base font-thin w-64 py-3 px-2 border border-yellow-500 hover:border-transparent rounded mb-8 uppercase max-w-sm mt-5 text-center text-white">
                            <Link target="_blank"
                                to="/contact-us">Contact Cymetrix</Link>
                        </div>
                    </div>
                </div>
            </div> 

            <div className="w-full" style={{backgroundColor: '#1e407b'}}>
                <div className="max-w-6xl mx-auto">
                <img className="mt-20 mx-auto image-product" src={require('./../../images/services/hire-salesforce-consultant-india.png')} alt="it tech staff augmentation on-demand hiring india"/>

                   
                </div>
            </div>

            <div className="section-title text-center m-8">
              
          <span className={`font-normal border-white border-none relative uppercase text-gray-800 sma:text-xl text-4xl  sm:text-xl md:text-4xl`}>Our Expertise
          </span>
        </div>
        <div className="mx-0 flex justify-center">

          <div className="relative max-w-6xl mx-auto  mb-10 sma:mx-3">
            <div class="flex flex-col sm:flex-row sm:-mx-2 mt-10">
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Salesforce</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div className="mt-2 text-base text-gray-700 text-left">Scale your Salesforce team without expanding your budget. Let Cymetrix help you fill the skill gap with our pre-vetted and certified Salesforce experts. Whether you are looking for a certified Salesforce Developer, Architect, Admin, or consultant, we are here to help you onboard niched Salesforce experts and avoid any recruitment delays. 
                  
                  </div>
                </div>
              </div>
              
              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Artificial Intelligence</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div className="mt-2 text-base text-gray-700 text-left">Don’t let the skill gap hold you back! We can help you onboard the AI specialists that you need, from Machine learning engineers to NLP experts and DevOps, bridge the skill gap with Cymetrix. Let our experts handle all the complexities, so your team can focus on core business process. 

                  
                  </div>
                </div>
              </div>

              <div class="mt-4 sm:w-1/3">
                <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                  <div className="text-gray-700 text-center text-lg">Data Analytics</div>
                  <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                  <div class="mt-2 text-base text-gray-700 text-left">We believe that Data is gold! But it’s only valuable if you can use it effectively. Get access to top Data Analysts who can help you transform your raw data into actionable insights. Our data science experts can design and implement data analysis solutions, create compelling reports and visualizations, and help you identify trends and opportunities to optimize your business strategy.
                  
                  </div>
                </div>
              </div>
            </div>
            

          </div>
        </div>



 
        <main className="w-full flex flex-col items-center" ref={ref}>
          <Title title="GET IN TOUCH" />
          <form className="justify-center flex" onSubmit={handleSubmit} enctype="multipart/form-data">
            <div className="bg-white rounded px-8 pt-6 pb-8 mb-4 flex flex-col max-w-6xl sm:w-10/12">
              <div className="-mx-3 md:flex mb-6 md:space-x-2">
                <div className="md:w-1/2 mb-6 md:mb-0">
                  <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="name">
                    Name*
                  </label>
                <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3" id="name" name="name"
                      type="text" placeholder="" />
              </div>
              <div className="md:w-1/2">
                <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="email">
                  Email*
                </label>
                <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3" id="email" name="email"
                      type="email" placeholder="" />
              </div>
            </div>
            <div className="-mx-3 md:flex mb-6">
              <div className="md:w-full">
                <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="subject">
                  Subject*
                </label>
                <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3"
                      id="subject" type="text" placeholder="" name="subject" />
              </div>
            </div>
            


            <div className="-mx-3 md:flex mb-6">
              <div className="md:w-full">
                <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="subject">
                  Message*
                </label>
                <textarea className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3"
                          id="message" type="text" placeholder="" name="message" >

                </textarea>
              </div>
            </div>
            <div className="-mx-3 md:flex mt-2">
              <div className="sma:w-full">
                <button
                  className="sma:w-full bg-header text-white font-bold py-2 px-4 border-b-4 hover:border-b-#263f5f2 border-gray-500 hover:border-gray-100 uppercase rounded-full" type="submit">
                  Send Message
                </button>
              </div>
            </div>
          </div>
        </form>
      </main>
    </Layout>
  );
}

export default CareerPage;
